<template>
  <div class="gatewayParam">
    <!-- 参数 -->
    <Head :name="'空调面板'" :add="'/pc/ProAir'" :adds="'/pc/ProAirParam'" :color="false" />
    <!-- 相关参数 -->
    <div class="paramBox">
      <div class="param">
        <div class="paramTit">相关参数</div>
        <div class="paramImg">
          <img src="../../../assets/product/airParam.png" alt class="img" />
          <div class="imgName">空调面板</div>
        </div>
        <div class="pas">
          <div class="pasLeft">
            <div class="li">
              <div class="pasName">产品型号:</div>
              <div class="pasCont">LSA01A</div>
            </div>
            <div class="li">
              <div class="pasName">产品尺寸:</div>
              <div class="pasCont">符合86盒标准：86×86mm</div>
            </div>
            <div class="li">
              <div class="pasName">额定输入:</div>
              <div class="pasCont">AC200V~250V/50Hz</div>
            </div>
            <div class="li">
              <div class="pasName">风管机控制（可选）:</div>
              <div class="pasCont">60W×5路</div>
            </div>
            <div class="li">
              <div class="pasName">工作温度:</div>
              <div class="pasCont">0°C-40°C</div>
            </div>
          </div>
          <div class="pasRight">
            <div class="li">
              <div class="pasName">工作湿度:</div>
              <div class="pasCont">0%-95%RH，无冷凝</div>
            </div>
            <div class="li">
              <div class="pasName">通信协议:</div>
              <div class="pasCont">自定义LSSN2.0</div>
            </div>
            <div class="li">
              <div class="pasName">工作频段:</div>
              <div class="pasCont">433.0MHz~453.0MHz</div>
            </div>
            <div class="li">
              <div class="pasName">无线发射功率:</div>
              <div class="pasCont">＜10dBm</div>
            </div>
          </div>
        </div>
        <div class="text">
          <div
            class="tx"
          >空调面板是客房内空调设备的主控面板，背板带有5线空调控制端口（阀开/阀关、高风/中风/低风），可独立控制两管制中央空调，也可配合单点遥控器或带红外遥控功能的人体感应探头，控制各种品牌的分体空调。显示采用LED段码屏，按键采用触摸感应式按键，产品美观耐用。</div>
        </div>
        <div class="star">* 以上所有数据来自BOT实验室，依据测量方式不同,实际结果可能略有差异。</div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "../../../components/Head.vue";
export default {
  components: {
    Head
  }
};
</script>
<style lang="less" scoped>
// 相关参数
.paramBox {
  width: 100%;
  background-color: #fff;
  .param {
    width: 12rem;
    margin: 0 auto;
    padding: 1.2rem 0px;
    .paramTit {
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #ff9000;
    }
    .paramImg {
      margin-top: 0.65rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        width: 3.01rem;
        height: 3.01rem;
      }
      .imgName {
        margin-top: 0.59rem;
        font-size: 0.36rem;
        line-height: 0.36rem;
        font-weight: 400;
        color: #333333;
      }
    }
    .pas {
      margin-top: 0.86rem;
      display: flex;
      text-align: left;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        .li {
          display: flex;
          flex-direction: row;
          margin-bottom: 0.38rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
          &:nth-of-type(4) {
            .pasName {
              margin-right: 0.5rem;
            }
          }
          &:last-of-type {
            margin-bottom: 0px;
          }
          .pasName {
            margin-right: 1.71rem;
          }
        }
      }
      .pasRight {
        .li {
          .pasName {
            margin-right: 1.21rem;
          }
          &:last-of-type {
            .pasName {
              margin-right: 0.68rem;
            }
          }
        }
      }
    }
    .text {
      margin-top: 0.43rem;
      border-top: 1px solid #333;
      padding-top: 0.4rem;
      text-align: justify;
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .star {
      margin-top: 0.8rem;
      text-align: left;
      font-size: 0.1rem;
      line-height: 0.1rem;
      font-weight: 300;
      color: #333333;
    }
  }
}
</style>
